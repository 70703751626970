<template>
  <div>
    <el-col :span="24" class="flex-between">
      <h2>
        {{ $t('formTitleI18n.TotalUnit') }}
        <span>{{ totalAmount | toThousandFilterTwo }} {{ $t('dollar') }}</span>
      </h2>
      <a class="recordBtn" href="javascript:;" @click="goSeeLogs">
        {{
        $t('unitPage.recordLogsBtn')
        }}
      </a>
    </el-col>
    <el-col :span="24" class="flex-between">
      <el-form ref="form" :model="formSearch" class="form-box">
        <el-form-item class="flex-row" :label="$t('unitPage.typeSelect')" v-if="isSupply">
          <el-select
            v-model="optionsValStatus"
            :placeholder="$t('unitPage.selectPlaceholder')"
            @change="changeStatus"
          >
            <el-option
              v-for="item in optionsStatus"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="flex-row" :label="$t('unitPage.typeSelect')" v-else>
          <el-select
            v-model="optionsValStatus"
            :placeholder="$t('unitPage.selectPlaceholder')"
            @change="changeStatus"
          >
            <el-option
              v-for="item in optionsInvestStatus"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div>
        <el-button
          class="btn-black"
          v-if="!isSupply"
          @click="$router.push({ path: $route.path + '/recharge' })"
        >{{ $t('btnI18n.Deposit') }}</el-button>
        <el-button
          class="btn-black"
          @click="$router.push({ path: $route.path + '/withdrawal' })"
        >{{ $t('btnI18n.Withdrawal') }}</el-button>
      </div>
    </el-col>
    <el-col :span="24">
      <div class="title">{{ $t('titleI18n.TransactionHistory') }}</div>
    </el-col>
    <el-table :data="dataList">
      <template slot="empty">
        <div v-loading="tableLoading" v-text="$t('tableEmpty')"></div>
      </template>
      <el-table-column :label="$t('tableI18n.Type')" prop="type" align="center">
        <template v-slot="scope">{{ scope.row.typeText }}</template>
      </el-table-column>
      <el-table-column :label="$t('unitPage.amountDollars')" prop="token" align="center">
        <template v-slot="scope">
          {{ (scope.row.token ? Number(scope.row.token).toFixed(2) : '0.00') | toThousandFilterTwo }}
          <!-- <span
            :class="scope.row.process === process.CONFIRM.value ? '' : ''"
          >{{ scope.row.amount }}</span>-->
        </template>
      </el-table-column>
      <!-- <el-table-column :label="$t('unitPage.unit')" prop="token" align="center">
        <template
          v-slot="scope"
        >{{ (scope.row.type === 2 || scope.row.type === 3) ? '-' : '+' }}{{ scope.row.token ? Number(scope.row.token).toFixed(2) : '0.00' }}</template>
      </el-table-column>-->
      <el-table-column :label="$t('tableI18n.DateofProposal')" prop="createdAt" align="center">
        <template v-slot="scope">{{ scope.row.createdAt }}</template>
      </el-table-column>
    </el-table>
    <AdPagination
      :currentPage="tablePageCP"
      :pageSize="tablePagePS"
      :pageTotal="tableTotal"
      @handlePage="handlePage"
    ></AdPagination>
  </div>
</template>

<script>
import AdPagination from '@/components/ad-pagination';
import filter from '@/filters';
export default {
  name: 'transaction-records',
  components: {
    AdPagination,
  },
  data() {
    return {
      isSupply: false,
      process: this.$enums.PROCESS,
      formSearch: {},
      optionsStatus: this.$enums.UNIT_SUPPLIER_TYPE,
      optionsInvestStatus: this.$enums.UNIT_INVEST_TYPE,
      optionsValStatus: '',
      totalAmount: '0.00',
      dataList: [],
      tableTotal: 0,
      tablePageCP: 1,
      tablePagePS: 10,
      tableLoading: false,
      tableEmpty: this.$t('loading'),
    };
  },
  mounted() {
    this.isSupply = this.$route.path.includes('supply');
    this.getDataList();
  },
  methods: {
    changeStatus() {
      this.getDataList();
    },
    handlePage(val) {
      this.tablePageCP = val.currentPage;
      this.tablePagePS = val.pageSize;
      this.getDataList();
    },
    getDataList() {
      this.tableLoading = true;
      const data = {
        type: this.optionsValStatus,
        page: this.tablePageCP,
        pageSize: this.tablePagePS,
      };
      if (this.optionsValStatus === '100' || !this.optionsValStatus) {
        delete data.type;
      }
      this.$axios
        .get('/v1/user/token-logs', { params: data })
        .then((response) => {
          if (response.code === 0 || response.code === '0') {
            this.dataList = response.data.rows || [];
            this.totalAmount = response.data.unitToken
              ? Number(response.data.unitToken).toFixed(2)
              : '0.00';
            this.tableTotal = response.data.count || 0;
            this.tableLoading = false;
            this.dataList.map((item) => {
              item.typeText = filter.unitType(item.type.toString());
            });
          } else {
            this.tableLoading = false;
            this.$message({ type: 'error', message: response.message });
          }
        })
        .catch((error) => {
          this.tableLoading = false;
        });
    },
    goSeeLogs() {
      if (this.isSupply) {
        this.$router.push({ path: '/supply-tr/record-logs' });
      } else {
        this.$router.push({ path: '/invest-tr/record-logs' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.recordBtn {
  color: #81d8d0;
  text-decoration: underline;
}
::v-deep .form-box {
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
